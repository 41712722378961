<template>
    <div>
      <h1>{{ species }} for Adoption</h1>
      <b-table striped hover :items="pets">
        <template #cell(name)="data"> <!-- same as -> slot="name" slot-scope="data" -->
          <router-link :to="`/pets/${species}/${data.index}`">{{ data.value }}</router-link>
        </template>
      <!-- <template slot="name" slot-scope="data">
        <router-link :to="`/pets/${data.index}`">{{ data.value }}</router-link>
      </template> -->
      </b-table>
    </div>
</template>

<script>
export default {
  props: {
    species: String,
    pets: Array
  }
}
</script>
