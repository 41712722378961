<template>
    <div>
        <!-- {{ $route.params.species }}
        {{ $route.params.id }} -->
        <h1>{{ animal.name }} ({{ $route.params.species }})</h1> <!-- set propert like name, age, breed -->
        <p>Age: {{ animal.age }} years old</p>
        <p>Breed: {{ animal.breed }}</p>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      animal: {} // set empty obj
    }
  },
  computed: {
    //   cats() {
    //       return this.$store.state.cats
    //   }
    ...mapState([
      'cats',
      'dogs'
    ])
  },
  mounted() {
    const animal = this[this.$route.params.species][this.$route.params.id]
    this.animal = animal
  }
// methods: {
  // pet() {
  // same as -> this.cats / this['cats']
  // const animal = this[this.$route.params.species][this.$route.params.id]
  // return animal
  // }
// }
}
</script>
